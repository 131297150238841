.parkpage{
  margin-top: 40px;
}
.map-info{
  position: relative;
}
.map-info img {
  width: 100%;
}
@media screen and (min-width: 1080px) and (max-width: 1080px){
  .parkpage{
    margin-top: 100px;
  }
}
.park-info{
  margin-bottom: 50px;
}
.park-info .item{
  background-color: #aaa;
  margin: 20px 10px 0;
  border-radius: 10px;
  padding: 20px;
}
.park-info .item .park-name p{
  font-weight: bolder;
  font-size: 22px;
  margin-bottom: 15px;
  color: #222;
}
.park-info .item .park-detail{
  font-size: 18px;
}
.park-info .item .nopark{
  color: #eb3e3e;
}
@media screen and (min-width: 1080px) and (max-width: 1080px){
  .park-info .item{
    margin: 30px 20px 0;
    border-radius: 10px;
    padding: 30px;
  }
  .park-info .item .park-name p{
    font-size: 36px;
    margin-bottom: 25px;
  }
  .park-info .item .park-detail{
    font-size: 28px;
  }
}